import _ from "lodash";
import { SliceZone, SliceZoneLike, SliceLike } from "@prismicio/react";
import { components } from "root/slices";

export type SliceTypeKey = keyof typeof components;

export type SlicesStyles = Partial<Record<SliceTypeKey, Record<string, string>>>;

export type SlicesConfig = {
  styles: SlicesStyles;
};

type CustomizedComponents = Record<SliceTypeKey, any>;

/**
 * Solution for customizing slices. As of the writing of this, Prismic doesn't
 * support defining custom props for SliceZone slices.
 */
const getCustomizedComponents = (propsSlicesStyles: SlicesStyles) => {
  return _.mapValues(components, (Component, key: SliceTypeKey) => {
    if (!Component) return null;

    const customProps = {
      styles: propsSlicesStyles?.[key] || {},
    };

    return (props: any) => <Component {...props} {...customProps} />;
  }) as CustomizedComponents;
};

export type HyamPrismicSliceZoneProps = {
  slices: SliceZoneLike;
  slicesStyles?: SlicesStyles;
  slicesStylesReplace?: SlicesStyles;
};

/**
 * Injects AnchorSection slices to AnchorSectionNavigation slice
 */
const setupAnchorSectionNavationSlice = (slices: SliceZoneLike<SliceLike<string>>) => {
  const anchorSectionNavSliceIndex = _.findIndex(
    slices,
    (slice: any) => slice?.slice_type === "anchor_section_navigation",
  );

  if (anchorSectionNavSliceIndex < 0) return slices;

  const anchorSectionSlices = _.filter(
    slices,
    (slice: any) => slice?.slice_type === "anchor_section",
  );

  (slices[anchorSectionNavSliceIndex] as any).__anchorSectionSlices = anchorSectionSlices;

  return slices;
};

export const HyamPrismicSliceZone = ({
  slicesStyles: propsSlicesStyles = {},
  ...props
}: HyamPrismicSliceZoneProps) => {
  const components = getCustomizedComponents(propsSlicesStyles);
  const slices = setupAnchorSectionNavationSlice(props.slices);

  return <SliceZone slices={slices} components={components} />;
};
