import { HyamPrismicSliceZone } from "hyam-prismic/components";
import { styles as moduleStyles, HyamPrismicDocumentHomePageStyles } from "./styles";
import { HomePageDocument } from "prismicio-types";
import { withConfigure } from "hyam-core/utils/withConfigure";

export type HyamPrismicDocumentHomePageProps = {
  document: HomePageDocument;
  styles?: Partial<HyamPrismicDocumentHomePageStyles>;
  stylesReplace?: Partial<HyamPrismicDocumentHomePageStyles>;
};

const _HyamPrismicDocumentHomePage = (props: HyamPrismicDocumentHomePageProps) => {
  const { document } = props;
  const styles = props.styles as HyamPrismicDocumentHomePageStyles;

  return (
    <div className={styles.container}>
      <HyamPrismicSliceZone slices={document.data.slices} />
    </div>
  );
};

export const HyamPrismicDocumentHomePage = withConfigure<
  HyamPrismicDocumentHomePageProps,
  HyamPrismicDocumentHomePageStyles
>({
  componentName: "HyamPrismicDocumentHomePage",
  Component: _HyamPrismicDocumentHomePage,
  moduleStyles,
});
